import React, { useEffect, useState } from 'react'
import { useScroll } from '../Hooks/useScroll'
import { useAchivements } from '../Hooks/useAchivements'
import { isMobile } from 'react-device-detect'
import TimeLine from '../components/ExpirienceTimeLine/TimeLine'


function AchivmentsTab({expState}) {  

  const {achivements, education, workExperience} = useAchivements()
  const { scrollToPage, setCurrentPage } = useScroll()
  const [variant, setVariant] = useState('new')


  return (
    isMobile?
    <div className='h-auto'>
      <div className='w-full h-full flex flex-row justify-center items-center'>
        <TimeLine expState={expState} setVariant={setVariant} type={'mobile'}/>
      </div>
    </div>
    :
    <div onClick={() => scrollToPage(2)} className='page_format'>
      <div className='w-full h-screen flex items-center justify-center'>
        <TimeLine setVariant={setVariant} type={'pc'}/>
      </div>
    </div>
  )
}

export default AchivmentsTab