import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import ContactForm from '../components/Form/Form'
import { Icon12Message, Icon16LogoVk, Icon16MailOutline, Icon24LogoInstagram, Icon28PaperplaneOutline } from '@vkontakte/icons'
import { ReactComponent as LogoTg } from "../accets/SVGs/telegram-5.svg";
import { ReactComponent as GitHub } from '../accets/SVGs/Github.svg'

function ContactsTab() {

  const [modalVisible, setModalVisible] = useState()
  const [info, setInfo] = useState({text: null, qr: null, link: null})

  const openModal = (type) => {
    switch (type) {
      case 'mail':
        setInfo({text: 'one@ognev.xyz', link: '', type: 'e-mail'})
        break;
      case 'vk':
        setInfo({text: '@pelemen000', link: 'https://vk.com/pelemen000', type: 'vk'})
        break;
      case 'insta':
        setInfo({text: '@atikin_vengo', link: 'https://www.instagram.com/atikin_vengo', type: 'instagram'})
        break;
      case 'telegram':
        setInfo({text: '@gigabyte_furhikov', link: 'https://t.me/gigabyte_furshikov', type: 'telegram'})
        break;
      case 'gitHub':
        setInfo({text: 'Nikita-Ognev/gitHub', link: 'https://github.com/Nikita-Ognev', type: 'gitHub'})
        break;
      default:
        break;
    }
    setModalVisible(true)
  }



  return (
    isMobile?
    <div className='w-full h-[120vh] bg-bg_primary flex flex-col relative mb-[6vh]'>
      <ContactForm/>
      <div className='w-full h-[20vh] spacer'/>
      <div className='w-full h-[20vh] bg-[#000000] flex flex-col items-center justify-center'>
      <div className='contact-box my-[2vh]'>
        <div className='icon-wrapper-m'>
          <Icon16MailOutline onClick={() => openModal('mail')} width={25} height={25} className='icon'/>
        </div>
        <div className='icon-wrapper-m'>
          <Icon16LogoVk  onClick={() => openModal('vk')} width={25} height={25} className='icon'/>
        </div>
        <div className='icon-wrapper-m'>
          <Icon24LogoInstagram onClick={() => openModal('insta')}  width={25} height={25} className='icon'/>
        </div>
        <div className='icon-wrapper-m text-text_accented'>
          <Icon28PaperplaneOutline  fill='#47feec' onClick={() => openModal('telegram')}  width={25} height={25} className='icon'/>
        </div> 
        <div className='icon-wrapper-m text-text_accented'>
          <GitHub fill='#47feec' onClick={() => openModal('gitHub')}  width={25} height={25} className='icon'/>
        </div>            
      </div>
        <div>
          <h1>Nikita Ognev | All Rights Reserved</h1>
        </div>
      </div>
      {
        modalVisible?
        <div onClick={() => setModalVisible(false)} className='w-full h-[100vh] bg-bg_primary bg-opacity-80 flex items-center justify-center fixed top-0 left-0 z-[200] cursor-pointer'> 
          <div className=' w-[80vw] h-[80vw] rounded-[20px] bg-bg_secondary  flex flex-col items-center justify-center'>
            <div className='w-full h-auto text-center items-center justify-center flex flex-col gap-y-5'>
              <div className='w-full h-auto text-left text-2xl flex flex-row gap-x-2 uppercase items-center justify-center'>
                <h1 className='font-bold text-text_accented'>{info.type}</h1>
              </div>
              <h1 className='text-3xl font-bold'>{info.text}</h1>
              {info.link === '' ? 
                <></>
                :
                <button className='border-2 border-[#47feec] w-[50vw] h-[5vh] rounded-2xl '>
                  <a href={info.link}>
                    <h1>go to {info.type}</h1>
                  </a>
                </button>
              }
            </div>
          </div>
        </div>
        :
        <></>
      }
    </div>
    :
    <div className='w-full h-[120vh] bg-bg_primary flex flex-col relative mb-[6vh]'>
      <ContactForm/>
      <div className='w-full h-[20vh] spacer'/>
      <div className='w-full h-[20vh] bg-[#000000] flex flex-col items-center justify-center'>
      <div className='contact-box my-[2vh]'>
        <div className='icon-wrapper-m'>
          <Icon16MailOutline onClick={() => openModal('mail')} width={25} height={25} className='icon'/>
        </div>
        <div className='icon-wrapper-m'>
          <Icon16LogoVk  onClick={() => openModal('vk')} width={25} height={25} className='icon'/>
        </div>
        <div className='icon-wrapper-m'>
          <Icon24LogoInstagram onClick={() => openModal('insta')}  width={25} height={25} className='icon'/>
        </div>
        <div className='icon-wrapper-m text-text_accented'>
          <Icon28PaperplaneOutline  fill='#47feec' onClick={() => openModal('telegram')}  width={25} height={25} className='icon'/>
        </div> 
        <div className='icon-wrapper-m text-text_accented'>
          <GitHub fill='#47feec' onClick={() => openModal('gitHub')}  width={25} height={25} className='icon'/>
        </div>             
      </div>
        <div>
          <h1>Nikita Ognev | All Rights Reserved</h1>
        </div>
      </div>
      {
        modalVisible?
        <div onClick={() => setModalVisible(false)} className='w-full h-[100vh] bg-bg_primary bg-opacity-80 flex items-center justify-center fixed top-0 left-0 z-[200] cursor-pointer'> 
          <div className=' w-[30vw] h-[30vw] rounded-[20px] bg-bg_secondary  flex flex-col items-center justify-center'>
            <div className='w-full h-auto text-center items-center justify-center flex flex-col gap-y-5'>
              <div className='w-full h-auto text-left text-2xl flex flex-row gap-x-2 uppercase items-center justify-center'>
                <h1 className='font-bold text-text_accented'>{info.type}</h1>
              </div>
              <h1 className='text-3xl font-bold'>{info.text}</h1>
              {info.link === '' ? 
                <></>
                :
                <button className='border-2 border-[#47feec] w-[15vw] h-[5vh] rounded-2xl '>
                  <a href={info.link}>
                    <h1>go to {info.type}</h1>
                  </a>
                </button>
              }
            </div>
          </div>
        </div>
        :
        <></>
      }
    </div>
  )
}

export default ContactsTab