import React, { useEffect, useState } from 'react'
import './dropDown.css'
import { label } from 'three/examples/jsm/nodes/Nodes.js'

function NavBarDropDown({setExpState}) {

  const tags = [
    {label: 'education', val: 'edu'}, 
    {label: 'work', val: 'work'},
    {label: 'achivements', val: 'achiv'},
  ]

  const [nonSelected, setNonSelected] = useState([{label: 'education', val: 'edu'}, {label: 'work', val: 'work'}])
  const [selectedItem, setSelectedItem] = useState({label: 'achivements', val: 'achive'})

  const handleDropDownChange = () => {
    const dropDown = document.getElementById('nav-drop-down')
    dropDown.classList.toggle('open')
  }

  const handleSelect = (value) => {
    setSelectedItem(value)
    setNonSelected(tags.filter((n) => n.label !== value.label))
  }

  useEffect(() => {
    setExpState(selectedItem.val)
  }, [selectedItem])


  return (
    <div onClick={handleDropDownChange} className='achivements-content drop-down-wrapper w-full h-full flex items-center justify-end text-[20px]'>
      <div className='drop-down'>
        <div className='item-selected'>
          <h1>{selectedItem.label}</h1>
        </div>
        <div id='nav-drop-down' className='down-elements'>
          {nonSelected.map((item) => {
            return (
              <div key={crypto.randomUUID()} onClick={() => handleSelect(item)} className='down-item'>
                <h1>{item.label}</h1>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default NavBarDropDown