import React, { useEffect, useState } from 'react'
import { useHref, useNavigate, useParams } from 'react-router'
import { useProjects } from '../Hooks/useProjects'
import { Icon12Cancel } from '@vkontakte/icons'
import { isMobile } from 'react-device-detect'
import { ReactComponent as ImagePlaceHolder } from '../accets/SVGs/imagePlaceHolder.svg'

function ProjectPage() {
  const { id } = useParams()
  const { getPrById } = useProjects()
  const [project, setProject] = useState(null)
  const navigate = useNavigate()
  
  useEffect(() => {
    setProject(getPrById(id)[0])
    console.log('project', project)
  }, [id])

  return (
    <div className='w-full h-full min-h-screen bg-bg_primary flex items-center justify-center'>
      {/* <div className='project-header w-full h-[400px]'>
        <div className='image-box w-[400px] h-[400px] flex items-center justify-center'>
          <div className='w-[250px] h-[250px] rounded-2xl bg-[#272727] border-2 border-[#686868] flex items-center justify-center'>
            <ImagePlaceHolder/>
          </div>
        </div>
        <div className='text-content'>
          <h1>{}</h1>
        </div>
      </div> */}
      <h1 className='text-[50px] font-bold'>Coming soon.....</h1>
    </div>
  )
}

export default ProjectPage