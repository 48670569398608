import React, { useEffect, useState } from 'react'
import './TimeLine.css'
import { useAchivements } from '../../Hooks/useAchivements'
import { isMobile } from 'react-device-detect'

function TimeLine({setVariant, type, expState}) {

  const {achivements, education, workExperience} = useAchivements()
  const [arr, setArr] = useState(education)
  
  let prev = 'time3';

  useEffect(() => {
    if (isMobile) return
    const nav = document.getElementById('achive-btns')

    if (!nav) return

    const navSelect = (e) => {
      if (e.target.attributes[1].nodeValue !== 'select-btn') return 
      document.getElementById(prev).classList.remove('selected')
      e.target.classList.add('selected')
      prev = e.target.id
      switch (e.target.id) {
        case 'time1':
          setArr(education)
          break;

        case 'time2':
          setArr(workExperience)
          break;

        case 'time3':
          setArr(achivements)
          break;

        default:
          break;
      }
    }

    nav.addEventListener('click', navSelect)

    return () => {
      nav.removeEventListener('click', navSelect)
    }
  }, [document.getElementById('achive-btns')]) 

  useEffect(() => {
    switch (expState) {
      case 'edu':
        setArr(education)
        break;

      case 'work':
        setArr(workExperience)
        break;

      case 'achiv':
        setArr(achivements)
        break;

      default:
        setArr(achivements)
        break;
    }
  }, [expState])

  useEffect(() => {
    document.getAnimations().forEach((anim) => {
      if (anim.animationName === 'render') {
        anim.cancel();
        anim.play();
      }
    })
  }, [arr])

  return (
    type === 'mobile' ? 
    <div className='w-[100%] h-screen overflow-scroll timeline-wrapper'>
      <div className=''>
        {arr.map((item, index) => {
          return (
          <div key={crypto.randomUUID()} className='mobile-time-line w-[90vw] ml-[2.5%] pl-[7.5%] flex flex-col items-start justify-center h-[300px] border-l-4 border-l-progress_line relative'>
            <div className='dot'></div>
            <div className='date'>{item.date}</div>
            <div className='content-line-item w-[100%] h-[200px]'>
              <h1 className='font-bold text-xl'>{item.title}</h1>
              <p className='text-ms'>{item.des}</p>
            </div>
          </div>
          )
        })}
      </div>
    </div>
    :
    <div className='w-[90%] h-screen overflow-scroll timeline-wrapper'>
      <div className='timeline-items'>
        {arr.map((item, index) => {
          return (
          <div key={crypto.randomUUID()} className='timeline-item'>
            <div className='timeline-dot'></div>
            <div className='timeline-date'>{item.date}</div>
            <div className='timeline-content'>
              <h1 className='text-[20px] font-bold'>{item.title}</h1>
              <h1 className='text-[18px] mt-[5px] font-semibold'>{item?.companyName}</h1>
              <h1 className='text-[18px] m-0 font-semibold'>{item?.role}</h1>
              <h1 className='text-[16px] mt-[10px] font-normal'>{item?.project}</h1>
              <p className='text-[14px] my-[5px] font-light'>{item.des}</p>
            </div>
          </div>
          )
        })}
      </div>
    </div>
  )
}

export default TimeLine