import React from 'react'
import './resumePage.css'
import avatarImage from '../../accets/Avatar.jpg'
import { useNavigate } from 'react-router'
import { isMobile } from 'react-device-detect'


function ResumePage() {


  const navigate = useNavigate()

  const expertise = [
    'React js',
    'JS',
    'TypeScript',
    'React Query',
    'UI|UX',
    'HTML||CSS',
    'JavaScript Core (DOM, AJAX, JSON)',
    'Git & GitHub',
    'REST API',
    'Maps API',
  ]

  const workExp = [
    {
      title: 'Стажировка в Университете СурГУ',
      сompanyName: 'Сургутский государственный университет',
      project: 'SurGUEvents',
      role: 'Frontend & UI|UX designer',
      date: '2021-2022',
      aboutWork: 'Создал прототип приложения в фигме, сверстал фронт нативного приложения для мобильного устройства используя reactNative, интегрировал фронт с беком ',
    },
    {
      title: 'Работа в стартап команде',
      сompanyName: 'N&D team',
      project: 'EvenTracker(standalone web/native) · Alumni · Eventracker(vk mini app)',
      role: 'Frontend & UI|UX designer & devOps',
      date: '2022-2024',
      aboutWork: 'Работал над прототипом, проводил кодревью (front), поднял дев и прод сервера, настроил CI|CD, осуществил интеграцию с MapBox GL JS и последуещей оптимизацией, сверстал адаптивный дизайн, отвечал за frontend в целом в приложении'
    },
  ]

  const courses = [
    {
      text: 'SkillBox курс "Frontend-pro"',
      date: '2022'
    },
    {
      text: 'Курс "Управление студенческим стартапом"',
      date: '2024'
    },
    {
      text: 'Курс "Разработка мини-приложений ВКонтакте"',
      date: '2024'
    }
  ]

  const skills = [
    {
      title: 'Разработка пользовательских интерфейсов',
      points: ['Создание интерактивных карт', 'Реализация пользовательских фильтров и настроек', 'Визуализация geojson-данных'],
    },
    {
      title: 'Взаимодействие с API картографических сервисов:',
      points: ['Интеграция с различными картографическими API (Mapbox GL JS)', 'Обработка геопространственных данных'],
    },
    {
      title: 'Разработка и тестирование',
      points: ['Написание unit-тестов', 'Баг-трекинг'],
    },
    {
      title: 'Дополнительные навыки',
      points: ['Оптимизация производительности', 'Адаптивная верстка', 'Работа с эффективной визуализацией большого обьема гео-данных'],
    },
  ]

  return (
    <div className='resume-wrapper '>
      <div className='resume'>
        <div className='left-side-menu'>
          <div className='avatar w-full h-[250px] flex items-center justify-center pt-[40px]'>
            <div className='shape  w-[175px] h-[175px] rounded-full bg-[#fff] overflow-hidden'>
              <img src={avatarImage} alt='' className='w-[100%] h-[100%] object-cover scale-125'/>
            </div>
          </div>
          <div className='contacts pl-[50px]'>
            <div className='mt-[45px] mb-[5px]'>
              <h1 className='text-[25px] font-bold'>Контакты</h1>
            </div>
            <div className='w-full  h-[2px] bg-[#dfdfdf]'/>
            <div className='phone my-[15px]'>
              <h1 className='text-[18px] font-bold'>Телефон</h1>
              <h2 className='text-[16px] font-light text-[#d1d1d1]'>89519720411</h2>
            </div>
            <div className='mail my-[15px]'>
              <h1 className='text-[18px] font-bold'>Почта</h1>
              <h2 className='text-[16px] font-light text-[#d1d1d1]'>one@ognev.xyz</h2>
            </div>
            <div className='address my-[15px]'>
              <h1 className='text-[18px] font-bold'>Адрес</h1>
              <h2 className='text-[16px] font-light text-[#d1d1d1]'>г. Казань, Советский район</h2>
            </div>
            <div className='other my-[15px]'>
              <h1 className='text-[18px] font-bold'>Доп. контакты</h1>
              <div className='w-auto mt-[13.5px] h-[45px]' >

              </div>
            </div>
          </div>
          <div className='education pl-[50px]'>
            <div className='mt-[45px] mb-[5px]'>
              <h1 className='text-[25px] font-bold'>Образование</h1>
            </div>
            <div className='w-full  h-[2px] bg-[#dfdfdf]'/>
            <div className='first my-[15px]'>
              <h1 className='text-[18px] font-bold'>Университет</h1>
              <h2 className='text-[16px] font-light text-[#d1d1d1]'>
                Казанский (приволжский) федеральный университет, 
                Институт вычеслительной математики и информационных технологий
              </h2>
              <h3 className='mt-[5px]'>(учусь в данный момент)</h3>
            </div>
          </div>
          <div className='specs pl-[50px]'>
            <div className='mt-[45px] mb-[5px]'>
              <h1 className='text-[25px] font-bold'>Компетентность</h1>
            </div>
            <div className='w-full  h-[2px] bg-[#dfdfdf] mb-[15px]'/>
            <div>
              {expertise.map((item) => {
                return (
                  <div key={crypto.randomUUID()} className='spec flex flex-row gap-x-[15px]  items-center'>
                    <div className='s-circle w-[5px] h-[5px] bg-[#dfdfdf] rounded-full'/>
                    <div className='s-text'>
                      <h1 className='text-[16px] font-thin'>{item}</h1>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='languages pl-[50px]'>
            <div className='mt-[20px] mb-[5px]'>
              <h1 className='text-[25px] font-bold'>Языки</h1>
            </div>
            <div className='w-full  h-[2px] bg-[#dfdfdf] mb-[15px]'/>
            <div>
              <h1 className='text-[14px] font-extralight'>Английский (профессиональный)</h1>
            </div>
            <div>
              <h1 className='text-[14px] font-extralight'>Русский (носитель)</h1>
            </div>
          </div>
        </div>
        <div className='content-about text-[#383c4c] p-[50px]'>
          <div className='h-[210px] mt-[40px]'>
            <div className='me-data flex flex-row gap-x-[15px] '>
              <h1 className='f-name text-[35px] font-extrabold'>Огнев</h1>
              <h1 className='nd-name text-[35px] font-light'>Никита Сергеевич</h1>
            </div>
            <div className='work-title'>
              <h1 className='text-[20px] font-light'>Frontend-developer</h1>
            </div>
            <div className='about-me'>
              <p className='text-[16px] mt-[10px]'>
                Веб-разработчик с 3-летним опытом, постоянно развивающий свои навыки.
                Имею опыт разработки веб/нативных-приложений и интеграции различных систем.
                Обладаю отличными коммуникативными навыками и умением работать в команде.
                Мотивирован идеями и стремлюсь создавать качественные продукты.
                Готов учиться новому и применять полученные знания на практике.
              </p>
            </div>
          </div>
          <div className='work-exp'>
            <div className='mt-[45px] mb-[5px]'>
              <h1 className='text-[25px] font-bold'>Опыт работы</h1>
            </div>
            <div className='w-full h-[2px] bg-[#383c4c]'/>
            <div className='work-box mt-[40px]'>
              {workExp.map((item) => {
                return (
                  <div key={crypto.randomUUID()} className='item-box relative w-full h-auto flex flex-col gap-y-[15px] px-[20px] border-l-2'>
                    <div className='date text-[16px] font-semibold'>
                      <div className='dot absolute left-0 top-0 translate-x-[-50%] translate-y-[25%] w-[15px] h-[15px] bg-[#dfdfdf] rounded-full border-[2px]'/>
                      <h1>{item.date}</h1>
                    </div>
                    <div className='company-name text-[16px] font-light'>
                      <h1>{item.сompanyName}</h1>
                    </div>
                    <div className='company-name text-[16px] font-light'>
                      <h1>{item.project}</h1>
                    </div>
                    <div className='company-name text-[16px] font-light'>
                      <h1>{item.role}</h1>
                    </div>
                    <div className='company-name text-[16px] font-light'>
                      <p>{item.aboutWork}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='courses min-h-[300px] w-full'>
            <div className='mt-[55px] mb-[5px]'>
              <h1 className='text-[25px] font-bold'>Доп образование</h1>
            </div>
            <div className='w-full h-[2px] bg-[#383c4c]'/>
            <div className='flex flex-col gap-y-[25px] mt-[40px]'>
              {courses.map((item) => {
                return (
                  <div key={crypto.randomUUID()} className='course-box flex flex-row justify-start items-center gap-x-5'>
                    <div className='circle w-[5px] h-[5px] bg-[#383c4c] rounded-full'/>
                    <div className='text-box'>
                      <h1>{item.text}</h1>
                      <h3>{item.date}</h3>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='work-skills mt-[48px] w-full'>
            <div className='mt-[50px] mb-[5px]'>
              <h1 className='text-[25px] font-bold'>Рабочие навыки</h1>
            </div>
            <div className='w-full h-[2px] bg-[#383c4c]'/>
            <div>
              {skills.map((item) => {
                return (
                  <div key={crypto.randomUUID()} className='mt-[15px]'>
                    <h1>{item.title}</h1>
                    {item.points.map((ndItem) => {
                      return (
                        <div key={crypto.randomUUID()} className='my-[5px]'>
                          <p className='text-[14px] font-light'>{ndItem}</p>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      {isMobile?
        <div className='pc-btns-resume w-[150px] h-auto flex flex-col fixed right-[25px] top-[25px] gap-y-5 cursor-pointer select-none'>
          <div onClick={() => navigate('/')} className='bg-[#6c7185] w-[100%] h-[40px] rounded-2xl flex items-center justify-center transition-[0.5s] hover:scale-105'>
            <h1 className='text-[14px] font-light'>Посетить сайт</h1>
          </div>
          <a href='http://public-file-storage.ognev.xyz/Resume.pdf'  className='bg-[#6c7185] w-[100%] h-[40px] rounded-2xl flex items-center justify-center transition-[0.5s] hover:scale-105'>
            <h1 className='text-[14px] font-light'>Скачать резюме</h1>
          </a>
        </div>
      :
        <div className='pc-btns-resume w-[150px] h-auto flex flex-col fixed right-[50px] top-[50px] gap-y-5 cursor-pointer select-none'>
          <div onClick={() => navigate('/')} className='bg-[#6c7185] w-[100%] h-[40px] rounded-2xl flex items-center justify-center transition-[0.5s] hover:scale-105'>
            <h1 className='text-[14px] font-light'>Посетить сайт</h1>
          </div>
          <a href='http://public-file-storage.ognev.xyz/Resume.pdf' className='bg-[#6c7185] w-[100%] h-[40px] rounded-2xl flex items-center justify-center transition-[0.5s] hover:scale-105'>
            <h1 className='text-[14px] font-light'>Скачать резюме</h1>
          </a>
        </div>
      }
    </div>
  )
}

export default ResumePage