import React, { useEffect, useState } from 'react'
import './ProjectsSlider.css'
import { useProjects } from '../../Hooks/useProjects'
import { motion } from 'framer-motion'
import { useText } from '../../Hooks/useText'
import { useNavigate, useNavigation } from 'react-router'
import { Icon28GridLayoutOutline } from '@vkontakte/icons'

 
function ProjectsSlider() {

  const { projects } = useProjects()
  const [prevrotate, setPrevRotate] = useState(-9)
  const [hovered, setHovered] = useState(false) 
  const [currentProject, setCurrentProject] = useState(null)
  const { refactorText } = useText()
  const navigate = useNavigate()

  const handleChangeCard = (type) => {
    type === 'next' ? 
      setPrevRotate((prev) => prev + (360 / projects.length))
      : 
      setPrevRotate((prev) => prev - (360 / projects.length))
  }
  const handleClickRemote = (type, handlde) => {
    handlde?
      handleChangeCard(type)
    :
      setPrevRotate((prev) => prev + (360 / projects.length))
  }

  useEffect(() => {
    const buttons = document.querySelectorAll('.buttons')
    for (const button of buttons) {
      if (button === null) return
      button.classList.toggle('hide')
    }
    let timerId = setInterval(() => handleClickRemote('next', false), 4000)
    hovered? clearInterval(timerId) : <></>
    return () => clearInterval(timerId);
  }, [hovered])


  const projectSelect = (item) => {
    document.getAnimations().forEach((anim) => {
      if (anim.animationName === 'reStart' || anim.animationName === 'reStartContent') {
        anim.cancel();
        anim.play();
      }
    })
    setTimeout(() => {
      setCurrentProject(item)
    }, 200)
  }

  return (
    <div className='content'>
      <div className='absolute left-[5vw] mt-[10vh] z-[100]'>
        <h1 className='text-5xl font-extrabold max-w-[60vw]'>Projects created by myself and my inspiration</h1>
        {currentProject?
        <div className='project-preView-content'>
          <div className='w-full h-full overflow-hidden'>
            <div onClick={() => navigate(`/project/${currentProject.id}`)} className='absolute right-[2rem] top-[2rem]'>
              <h1>See More</h1>
            </div>
            <div className='flex flex-col gap-y-5'>
              <h1 className='text-[30px] font-bold text-text_accented'>{currentProject?.title}</h1>
              <h1 className='text-[25px] font-bold text-text_primary'>{currentProject?.date}</h1>
              <h1 className='text-[20px] font-semibold text-text_primary '>{refactorText(currentProject?.topic, 300)}</h1>
              <h1 className='text-[15px] font-light text-text_primary'>{refactorText(currentProject?.des, 250)}</h1>
            </div>
          </div>
        </div>
        :
        <div className='project-preView-content flex items-center justify-center'>
          <h1 className='text-[1.5rem] font-bold '>Nothing selected</h1>
        </div>
        }
      </div>
      <motion.div className='w-[112vw] h-[105vh] flex flex-row items-center  justify-center overflow-hidden top-[50%] left-[110%] translate-y-[-50%] translate-x-[-50%] absolute z-40 '
        onHoverStart={() => setHovered(true)}
        onHoverEnd={() => setHovered(false)}
      > 
        <div className='slider' style={{'--rotate': `${prevrotate}`}}>
          {projects.map((item, index) => {
            return (
              <div key={index} className='item select-none cursor-pointer' style={{'--i': `${index}`}}>
                <div onClick={() => projectSelect(item)}  style={{'--ic': `${index}`, backgroundImage: `url(${item.img})`}} className='item-content'>
                  <div className='w-[90%] h-auto'>
                    <h1 className='text-3xl font-bold'>{item.title}</h1>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </motion.div>
      <motion.div className='absolute right-[0vw] top-[50%] translate-x-[-50%] translate-y-[-50%] z-[100] flex flex-col gap-y-4'
        onHoverStart={() => setHovered(true)}
        onHoverEnd={() => setHovered(false)}
      > 
        <button onClick={() => handleClickRemote('next', true)} className='hover:scale-125 duration-200 hover:duration-200'>следующий</button>
        <button onClick={() => navigate('/projects')} className='hover:scale-125 duration-200 hover:duration-200'>
          <h1>Все проекты</h1>
        </button>
        <button onClick={() => handleClickRemote('prev', true)} className='hover:scale-125 duration-200 hover:duration-200'>предыдущий</button>
      </motion.div>
    </div>
  )
}

export default ProjectsSlider