import { Outlet, useHref, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import NavBar from "./components/NavBar";


export default function MainPageLayout({children}) {

  const [expState, setExpState] = useState()

  return(
    <div>
      <NavBar setExpState={setExpState}/>
      <Outlet context={[expState]} />
    </div>
  )
}