import { useState } from "react"
import eventBg4 from '../accets/IMGs/bgForEventCard/bg_event_4.jpg'
import eventBg5 from '../accets/IMGs/bgForEventCard/bg_event_5.jpg'
import eventBg6 from '../accets/IMGs/bgForEventCard/bg_event_6.jpg'
import eventBg7 from '../accets/IMGs/bgForEventCard/bg_event_7.jpg'
import eventBg8 from '../accets/IMGs/bgForEventCard/bg_event_8.jpg'
import eventBg9 from '../accets/IMGs/bgForEventCard/bg_event_9.jpg'
import eventBg10 from '../accets/IMGs/bgForEventCard/bg_event_10.jpg'
import eventBg11 from '../accets/IMGs/bgForEventCard/bg_event_11.jpg'
import eventBg12 from '../accets/IMGs/bgForEventCard/bg_event_12.jpg'



import imgLogo from '../accets/IMGs/icon_r50_512 1.png'

export const useProjects = () => {
    const link = 'https://vk.com/services?ref=catalog_recent&w=app51780803_467966674'
    const projects = [
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'EvenTracker VK mini app',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: eventBg10,
            des: 'Vk миниапп, который делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Он также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            id: 1,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'EvenTracker native mobile app',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: eventBg12,
            des: 'Нативное приложение, которое делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Оно также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            id: 2,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'EvenTracker web app',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: eventBg11,
            des: 'Web-приложение, которое делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Оно также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            id: 3,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Alumni telegram mini app',
            topic: 'Alumni поможет тебе поддержвать отношения с твоими одногрупниками после обучения, и узнать кто чего добился, и чем может помочь',
            img: eventBg4,
            des: 'Telegram-miniapp который помогает решать какие то жизненные проблемы с помощью старых знакомств, информируя пользователя о том чем могут помочь его бывшые однокурсники',
            id: 4,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Surgu Events web app',
            topic: 'Навигатор мероприятий в университете',
            img: eventBg5,
            des: 'Web-приложение которое помогает организовывать и проводить университетские мероприятия',
            id: 5,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Surgu Events native mobile app',
            topic: 'Навигатор мероприятий в университете',
            img: eventBg6,
            des: 'Нативное приложение которое помогает организовывать и проводить университетские мероприятия',
            id: 6,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Friendify native mobile app',
            topic: 'Friendify поможет тебе спланировать время которое ты хочешь провести с друзьями',
            img: eventBg7,
            des: 'Нативное-приложение которое помогает выбрать как провести время компанией и подробно распланировать встречи',
            id: 7,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Friendify web app',
            topic: 'Friendify поможет тебе спланировать время которое ты хочешь провести с друзьями',
            img: eventBg8,
            des: 'Web-приложение которое помогает выбрать как провести время компанией и подробно распланировать встречи',
            id: 8,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Ognev site',
            topic: 'Мой сайт)',
            img: eventBg9,
            des: 'Тут можно узнать обо мне',
            id: 9,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
    ]

    /**
     * @id
     */
    const getPrById = (id) => {
        let res = projects.filter((item) => item.id == id)
        return res
    }


    return {
        projects,
        getPrById,
    }
}