import { easeIn, easeInOut } from "framer-motion";
import React, { useEffect } from "react";
import { useDimensions } from "react-hook-dimensions";
import { useAppNavigate } from './useAppNavigate'
import { useLS } from "./useLS";

export const useScroll = () => {
    const pageCount = 4 - 1
    const [scrollYPosition, setScrollYPosition] = React.useState(0);
    const [scrolledPercent, setScrolledPercent] = React.useState()
    const [currentPage, setCurrentPage] = React.useState(0)
    const { saveLastPage } = useLS()
    const authorTabRef = React.useRef(null)
    const achivmentsTabRef = React.useRef(null)
    const projectsTabRef = React.useRef(null)
    const height = window.innerHeight * pageCount 

    const handleScroll = () => {
        const newScrollYPosition = window.scrollY;
        let page = window.scrollY / window.innerHeight;
        setScrollYPosition(newScrollYPosition);
        if (Number.isInteger(page)) {
            const nav = document.getElementById('navBar')
            setTimeout(() => {
                page === 0 || page === 2? <></> : nav.classList.add('hidden-navBar')
            }, 500) 
        }
    };

    const getCurrentPage = () => {
        let page;
        page = scrollYPosition / window.innerHeight
        if (page <= 0) setCurrentPage(0) 
        if (page > 0) setCurrentPage(Math.round(page))
    }
    React.useEffect(() => {
        setScrolledPercent((scrollYPosition / height) * 100)
        getCurrentPage()
    }, [scrollYPosition])
  
    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {window.removeEventListener('scroll', handleScroll)}
    }, []);

    /**
     * @index
     */
    const scrollToPage = (index) => {
        window.scroll({
            top: window.innerHeight * (index),
            behavior: 'smooth'
          })
    }

    const scrollToContacts = () => {
        window.scroll({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
          })
    }

    return {
        scrolledPercent,
        currentPage,
        scrollToPage,
        authorTabRef,
        achivmentsTabRef, 
        projectsTabRef,
        setCurrentPage,
        scrollToContacts
    }
}